<template>
  <v-container fluid>
    <v-img
      contain
      max-width="540"
      src="../assets/mapArt/NjMapArt.jpg"
      style="margin: auto; display: block;"
    ></v-img>
    <v-row
      justify="center"
      align="center"
      style="padding-top:50pt; text-align: center; font-size: 0.75rem"
    >
      <div style="max-width:320pt">
        A nice looking data set I recieved from a surveyor at a remediation job.
        Somewhere in New Jersey.
        <br />
        <br />
        Made in ESRI ArcGIS
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>
